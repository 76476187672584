export function header() {

    const path = location.pathname;

    const header = document.querySelector('.j-header');
    const header_btn = document.querySelector('.j-header_btn');
    const header_links = document.querySelectorAll('.j-header_link');

    if(header) {
        header_btn.addEventListener('click', () => {
            if (header.classList.contains('active')) {
                header.classList.remove('active');
            } else {
                header.classList.add('active');
            }
        });

        const bgWhite = document.querySelectorAll('.js-bgWhite');
        window.addEventListener('scroll', () => {
            bgWhite.forEach((section) => {
                const sectionHeight = section.offsetHeight;
                const sectionTop = section.offsetTop - header.offsetHeight;
                const scrollPosition = window.pageYOffset;

                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                header.classList.add('dark');
                } else {
                header.classList.remove('dark');
                }
            });
        });
        //ページごとにヘッダーにクラス付与
        [...header_links].forEach((link) => {
            if (link.dataset.page == path) {
                link.classList.add("is-here")
            }
        });
    }
}